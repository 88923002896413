<template>
    <div class="content-box">
        <div class="cont-header">
           <a-row>
            <a-col :span="1"></a-col>
            <a-col :span="11">
                <span :selected="selType==1" @click="onClickHeader(1)">零售订单</span>
                <span :selected="selType==2" @click="onClickHeader(2)" style="margin-left:10%;">会员订单</span>
                <span :selected="selType==3" @click="onClickHeader(3)" style="margin-left:10%;">套餐订单</span>
            </a-col>
            <!-- <a-col :span="12" v-if="selType==1" style="text-align:right;padding-right:5%;">
                <a-radio-group v-model="orderType" @change="onClickSel">
                    <a-radio :value="2">
                        已支付
                    </a-radio>
                    <a-radio :value="0">
                        待支付
                    </a-radio>
                    <a-radio :value="1">
                        支付中
                    </a-radio>
                    
                </a-radio-group>
            </a-col> -->
             <a-col :span="12" style="text-align:right;padding-right:5%;">
                <a-radio-group v-model="orderType" @change="onClickSelVip">
                    <a-radio :value="4">
                        全部
                    </a-radio>
                    <a-radio :value="1">
                        待支付
                    </a-radio>
                    <a-radio :value="2">
                        已支付
                    </a-radio>
                    <a-radio :value="3">
                        已失效
                    </a-radio>
                </a-radio-group>
            </a-col>
           </a-row> 
        </div>
        <div class="cont" v-if="selType==1" >
            <order-list :requestApi="$axios.OrderList" :params="musicTableParams" :orderType=selType></order-list>
        </div>
         <div class="cont" v-if="selType==2">
            <vip-order-list :requestApi="$axios.viporderList" :params="musicTableParams" :orderType=selType></vip-order-list>
        </div>
        <div class="cont" v-if="selType==3">
            <package-list :params="musicTableParams"></package-list>
        </div>
    </div>
    
</template>
<script>
    import VipOrderList from './components/VipOrderList.vue';
    import OrderList from './components/OrderList.vue';
    import PackageList from './components/PackageList.vue';
    export default {
        data() {
            return {
                selType: 1,
                orderType: 4,
                viporderType: 0,
                musicTableParams: {},
            }
        },
        components: {
            OrderList,
            VipOrderList,
            PackageList,
        },
        created: function () {
            this.getData();
        },
        methods: {
            onClickHeader(idx){
                this.selType = idx;
                this.getData();
            },
            onClickSel(e){
                this.orderType = e.target.value;
                this.getData();
            },
            onClickSelVip(e){
                this.viporderType = e.target.value;
                this.getData();
            },
            getData(){
                let params = {};
                // if(this.selType == 1){
                //     params.type = this.orderType;
                // }
                // if(this.selType == 2){
                //     params.type = this.viporderType;
                // }
                params.type = this.orderType;
                this.musicTableParams = Object.assign({}, this.musicTableParams, params);
            },
        },
    }
</script>
<style lang="scss" scoped>  
    .cont-header{
        line-height: 60px;
        border-bottom:1px solid #F0F4F5;
        background-color: #FFFFFF;
        span{
            display: inline-block;
            line-height: 60px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
        }
        span[selected]{
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
        span:hover{
            cursor: pointer;
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
    }
    .cont{
        /* width: 90%; */
        margin: 0 auto;
        padding-top:24px;
    }
</style>